<script lang="ts" setup="">

interface IProps {
	modelValue?: any
	required?: boolean
	name?: string
	id?: string
	label?: string
	hint?: string
	placeholder?: string
	type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search'
	max?: number | string
	min?: number | string
	loading?: boolean
}

interface IEmits {
	(e: 'input', value: any): void

	(e: 'change', value: any): void

	(e: 'update:modelValue', value: any): void
}

const emit = defineEmits<IEmits>()
const props = withDefaults(defineProps<IProps>(), {
	modelValue: null,
	loading: false
})
const value = computed({
	get () { return props.modelValue || '' },
	set (v: any, old: any) {
		emit('input', v)
		emit('update:modelValue', v)
		if (v !== old) { emit('change', v) }
	}
})
</script>
<template>
	<div>
		<label for="project-name" class="block text-sm font-medium leading-6 text-gray-900">
			<slot name="label">{{ label }}</slot>
			<span v-if="required" class="text-red-500">*</span>
		</label>
		<p class="text-xs font-medium leading-6 text-gray-500">
			<slot name="hint">{{ hint }}</slot>
		</p>
		<div class="mt-2">
			<SkeletonLoader v-if="loading" class="w-full h-10"/>
			<input
				v-else
				v-model="value"
				:type="type"
				:name="name"
        :placeholder="placeholder"
				:id="id"
        :max="max"
        :min="min"
        v-bind="props"
				class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 bg-white"
			/>
		</div>
	</div>
</template>
